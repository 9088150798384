<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("corrective_types") }}</h1>
      <button
        v-if="role != 99"
        @click="open = true"
        class="bg-green-500 px-3 rounded text-gray-200"
      >
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <div class="flex">
      <div class="flex-1 overflow-hidden">
        <el-table
          :data="correctiveTypes"
          border
          v-loading="loading"
          style="width: 100%"
          class="rounded-lg shadow-md"
        >
          <el-table-column fixed prop="id" label="ID" width="60">
          </el-table-column>
          <el-table-column
            fixed
            prop="name"
            :label="$t('name')"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            :label="$t('minimal')"
            prop="minimal"
            min-width="130"
          >
          </el-table-column>
          <el-table-column :label="$t('average')" prop="average" min-width="90">
          </el-table-column>
          <el-table-column
            :label="$t('minimal')"
            prop="maximal"
            min-width="130"
          >
          </el-table-column>
          <el-table-column
            v-if="role != 99"
            :label="$t('actions')"
            fixed="right"
            :width="actionsWidth"
          >
            <template slot-scope="scope">
              <el-button size="mini" @click="openEdit(scope.row.id)">
                <i class="el-icon-edit"></i>
                <span class="hidden lg:inline-block">{{ $t("edit") }}</span>
              </el-button>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('you_want_to_delete')"
                class="ml-2"
                @confirm="correctiveTypeDelete(scope.row.id)"
              >
                <el-button slot="reference" size="mini" type="danger">
                  <i class="el-icon-delete"></i>
                  <span class="hidden lg:inline-block">{{ $t("delete") }}</span>
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-3 flex justify-end">
          <el-pagination
            background
            layout="prev, pager, next"
            hide-on-single-page
            @prev-click="Prev"
            @next-click="Next"
            @current-change="handleCurrentChange"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>

      <el-drawer
        :visible.sync="open"
        :with-header="false"
        size="400px"
        @close="CloseDrawer"
        v-if="role != 99"
      >
        <div
          class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
        >
          <p>SYSTEM</p>
          <button @click="open = false" class="px-2">
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div class="p-4">
          <h1 class="mb-4">{{ $t("add_corrective_type") }}</h1>
          <el-form
            ref="corrective_type"
            label-position="top"
            :rules="rules"
            :model="corrective_type"
            @submit.prevent.native
          >
            <el-form-item
              prop="name"
              :label="$t('name')"
              :rules="{
                required: true,
                message: $t('please_input'),
                trigger: 'blur',
              }"
            >
              <el-input
                :placeholder="$t('input')"
                v-model="corrective_type.name"
                clearable
              >
              </el-input>
            </el-form-item>
            <div class="flex -mx-1">
              <el-form-item
                prop="minimal"
                :label="$t('minimal')"
                class="w-1/3 inline-block px-1"
              >
                <el-input
                  :placeholder="$t('input')"
                  v-model.number="corrective_type.minimal"
                  clearable
                >
                  >
                </el-input>
              </el-form-item>
              <el-form-item
                prop="average"
                :label="$t('average')"
                class="w-1/3 inline-block px-1"
              >
                <el-input
                  :placeholder="$t('input')"
                  v-model.number="corrective_type.average"
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item
                prop="maximal"
                :label="$t('maximal')"
                class="w-1/3 inline-block px-1"
              >
                <el-input
                  :placeholder="$t('input')"
                  v-model.number="corrective_type.maximal"
                  clearable
                >
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="text-right mt-6">
            <el-button
              v-if="!edit"
              @click="addCorrectiveType"
              type="primary"
              :loading="loading"
            >
              {{ $t("add") }}
            </el-button>
            <el-button
              v-else
              @click="editCorrectiveType"
              type="primary"
              :loading="loading"
            >
              {{ $t("save") }}
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "correctiveType",
  data() {
    var checkNumber = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error(this.$t("please_input_digits")));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      actionsWidth: 270,
      open: false,
      edit: false,
      id: "",
      corrective_type: {
        name: "",
        minimal: "",
        average: "",
        maximal: "",
      },
      edit_corrective_type: "",
      correctiveTypes: [],
      totalCount: 0,
      count: 1,
      loading: false,
      role: localStorage.role,
      rules: {
        minimal: [
          {
            validator: checkNumber,
            trigger: "blur",
          },
        ],
        average: [
          {
            validator: checkNumber,
            trigger: "blur",
          },
        ],
        maximal: [
          {
            validator: checkNumber,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getCorrectiveTypes();
    window.innerWidth < 992 ? (this.actionsWidth = 120) : "";
  },
  methods: {
    async getCorrectiveTypes() {
      this.loading = true;
      await axios
        .get(
          `corrective-types/${this.count === 1 ? "" : "?page=" + this.count}`
        )
        .then((response) => {
          this.totalCount = response.data.count;
          this.correctiveTypes = response.data.results;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getCorrectiveTypes();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getCorrectiveTypes();
    },
    Next() {
      this.count++;
      this.getCorrectiveTypes();
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.id = id;
      this.edit_corrective_type = this.correctiveTypes.find(
        (data) => data.id === id
      );
      Object.keys(this.corrective_type).forEach((key) => {
        this.corrective_type[key] = this.edit_corrective_type[key];
      });
    },
    CloseDrawer() {
      this.edit = false;
      Object.keys(this.corrective_type).forEach((key) => {
        this.corrective_type[key] = "";
      });
      this.$refs.corrective_type.clearValidate();
    },
    editCorrectiveType() {
      this.$refs.corrective_type.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = Object.assign({}, this.corrective_type);
          Object.keys(data).forEach((key) => {
            if (data[key] === this.edit_corrective_type[key]) {
              delete data[key];
            }
          });
          if (Object.keys(data).length != 0) {
            axios
              .patch(`corrective-types/${this.id}/`, data)
              .then(() => {
                this.loading = false;
                this.open = false;
                this.getCorrectiveTypes();
                this.$notify({
                  title: this.$t("success"),
                  message: this.$t("data_changed"),
                  type: "success",
                });
              })
              .catch(() => {
                this.loading = false;
                this.$notify.error({
                  title: this.$t("error"),
                  message: this.$t("data_unchanged"),
                });
              });
          } else {
            this.loading = false;
            this.open = false;
            this.getCorrectiveTypes();
            this.$notify({
              title: this.$t("success"),
              message: this.$t("data_changed"),
              type: "success",
            });
          }
        }
      });
    },
    addCorrectiveType() {
      this.$refs.corrective_type.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = Object.assign({}, this.corrective_type);
          Object.keys(data).forEach((key) => {
            if (data[key] === "") {
              delete data[key];
            }
          });
          axios
            .post("corrective-types/", data)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getCorrectiveTypes();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    async correctiveTypeDelete(id) {
      this.loading = true;
      await axios
        .delete(`corrective-types/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getCorrectiveTypes();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
